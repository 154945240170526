import React, { useState } from "react";
import { NavLink } from "react-router-dom";

function NavItem({
  url,
  title,
  subMenuItems,
  onClick,
  mobileMode,
  dropdownActive,
  handleDropdown,
  handleDropdownLeave,
}) {
  return (
    <div className="menu-item">
      <NavLink
        to={mobileMode && subMenuItems ? "#" : `/${url}`}
        className="link-to-page"
        onClick={onClick}
        onMouseEnter={mobileMode ? null : handleDropdown}
      >
        {title}
      </NavLink>
      {subMenuItems && dropdownActive && !mobileMode && (
        <div
          className="menu-item-dropdown-holder"
          onMouseLeave={() => handleDropdownLeave()}
        >
          <div
            className="py-1"
            role="menu"
            aria-orientation="vertical"
            aria-labelledby="options-menu"
          >
            {subMenuItems.map((subMenuItem, index) => (
              <NavLink
                to={`/${subMenuItem.url}`}
                className="menu-dropdown-item"
                role="menuitem"
                key={index}
                onClick={onClick}
              >
                {subMenuItem.title}
              </NavLink>
            ))}
          </div>
        </div>
      )}
    </div>
  );
}

export default NavItem;
