import React, { useContext, useState, useEffect } from "react";
import { SettingsContext } from "../context";

function TermsAndConditions() {
  const settings = useContext(SettingsContext);
  const [NAME, setNAME] = useState("");
  const [EMAIL, setEMAIL] = useState("");

  useEffect(() => {
    if (settings) {
      setNAME(settings.name);
      setEMAIL(settings.businessInfo.email);
    }
  }, [settings]);

  return (
    <div className="page">
      <div className="page-container">
        <h1 className="page-title" style={{ color: "var(--primary-color)" }}>
          Общи условия
        </h1>
        <h3 className="page-subtitle">Въведение</h3>
        <p className="paragraph">
          Добре дошли в нашия уебсайт! Моля, прочетете внимателно тези Общи
          условия преди да използвате нашите услуги. Чрез използването на този
          сайт, вие се съгласявате да спазвате и да бъдете обвързани от тези
          Общи условия.
        </p>
        <h3 className="page-subtitle">Интелектуална собственост</h3>
        <p className="paragraph">
          Всички текстове и снимки на този уебсайт са собственост на
          <span className="font-semibold"> {NAME} </span>или са предоставени с
          лиценз от трети страни. Всички права са запазени. Всяко използване,
          копиране или разпространение на материалите на този уебсайт без
          писменото съгласие на собственика е строго забранено.
        </p>
        <h3 className="page-subtitle">Отказ от отговорност</h3>
        <p className="paragraph">
          Този уебсайт се предоставя "както е" без каквито и да е гаранции.
          <span className="font-semibold"> {NAME} </span>не поема отговорност за
          точността, пълнотата или актуалността на информацията на този уебсайт.
        </p>
        <h3 className="page-subtitle">Промени в Общите условия</h3>
        <p className="paragraph">
          <span className="font-semibold"> {NAME} </span>може да променя тези
          Общи условия по всяко време без предупреждение. Промените ще влязат в
          сила веднага след публикуването на новите Общи условия на този
          уебсайт.
        </p>
        <h3 className="page-subtitle">Контакти</h3>
        <p className="paragraph">
          Ако имате въпроси относно тези Общи условия, моля свържете се с нас на
          <a href={`mailto:${EMAIL}`} className="font-semibold">
            {" "}
            {EMAIL}
          </a>
          .
        </p>
        <p className="paragraph italic mt-10">
          Дизайн и разработка на уебсайта -{" "}
          <a href={"mailto:office@foks.bg"} className="font-semibold">
            ФОКС - PR и Рекламна агенция
          </a>
          .
        </p>
      </div>
    </div>
  );
}

export default TermsAndConditions;
