import React from "react";
import ImageCarousel from "../components/ImageCarousel";
import TextWithImageSection from "../components/TextWithImageSection";
import TitleSection from "../components/TitleSection";
import CardsSection from "../components/CardsSection";
import { Link } from "react-router-dom";
import Meta from "../components/Meta";
import TextWithGoogleMapSection from "../components/TextWithGoogleMapSection";

function Page({ title, content, metaDescription }) {
  const isDataLoaded = content !== undefined;
  const itemsFromContent = Object.values(isDataLoaded ? content : [{}]);
  const itemTypes = itemsFromContent.map((item, index) => {
    return item._type;
  });

  function buildPage() {
    let component;
    let componentsArray = [];
    let sectionsArray = [];
    itemTypes.map((type, index) => {
      if (type === "titleSection") {
        component = (
          <TitleSection
            content={itemsFromContent[index]}
            key={`${index}-${type}`}
          />
        );
        componentsArray.push(component);
      } else if (type === "carousel") {
        component = (
          <ImageCarousel
            content={itemsFromContent[index].content}
            background={
              itemsFromContent[index].background !== undefined
                ? itemsFromContent[index].background.color
                : "white"
            }
            key={`${index}-${type}`}
          />
        );
        componentsArray.push(component);
      } else if (type === "cards") {
        component = (
          <CardsSection
            content={itemsFromContent[index]}
            key={`${index}-${type}`}
          />
        );
        componentsArray.push(component);
      } else if (type === "featuredCards") {
        component = (
          <CardsSection
            content={itemsFromContent[index]}
            cardType="featured"
            key={`${index}-${type}`}
          />
        );
        componentsArray.push(component);
      } else if (type === "textImageSection") {
        component = (
          <TextWithImageSection
            content={itemsFromContent[index]}
            key={`${index}-${type}`}
          />
        );
        componentsArray.push(component);
      } else if (type === "textGoogleMapSection") {
        component = (
          <TextWithGoogleMapSection
            content={itemsFromContent[index]}
            key={`${index}-${type}`}
          />
        );
        componentsArray.push(component);
      }
    });
    return componentsArray;
  }

  return (
    <>
      <Meta
        title={title}
        description={metaDescription !== undefined ? metaDescription : ""}
      />
      <div className="page">
        <div className="page-container">
          <h1 className="page-title" style={{ color: "var(--primary-color)" }}>
            {title}
          </h1>
          {isDataLoaded && buildPage()}
        </div>
      </div>
    </>
  );
}

export default Page;
