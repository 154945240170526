import React from "react";

function NotFound() {
  return (
    <div className="page">
      <div className="page-container my-20">
        <h1 className="page-title" style={{ color: "var(--primary-color)" }}>
          404 - Страницата която търсите не съществува.
        </h1>
      </div>
    </div>
  );
}

export default NotFound;
