import React, { useState, useEffect } from "react";
import { urlFor } from "../functions";
import placeholder from "../media/placeholder-image.png";

function Card({ style, image, title, link, publishDate }) {
  const [source, setSource] = useState("");
  let cardStyle = style === "featured" ? "featured-card" : "card";

  useEffect(() => {
    setSource(urlFor(image).auto("format").fit("max").toString());
  }, []);

  const titlePosition = publishDate ? "items-start" : "items-center";

  return (
    <div className={`${cardStyle} ${titlePosition}`}>
      <div
        className="card-image"
        style={{
          backgroundImage: `url(${source})`,
        }}
      ></div>
      <div className="card-container">
        {publishDate && (
          <span
            className="publish-date"
            style={{ color: "var(--primary-color)" }}
          >
            {publishDate}
          </span>
        )}
        <h3 className="card-title">{title}</h3>
      </div>
    </div>
  );
}

export default Card;
