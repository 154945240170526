import React, { useState, useEffect } from "react";
import Card from "./Card";
import CardXL from "./CardXL";
import { Link } from "react-router-dom";
import sanityClient from "../client";
import { useMediaQuery } from "react-responsive";

function CardContent({ card, index, cardStyle, cardType }) {
  return (
    <>
      {cardType === "CardXL" ? (
        <CardXL
          image={card.image}
          title={card.title}
          description={card.description}
          link={card.url}
          price={card.price}
        />
      ) : (
        <Link to={`/${card.url}`} key={`card-${index}`}>
          <Card
            style={cardStyle}
            image={card.image}
            title={card.title}
            publishDate={card.publishDate}
          />
        </Link>
      )}
    </>
  );
}

function CardsSection({ cardStyle, content }) {
  const isDataLoaded = content !== undefined;
  const [sectionButtonURL, setSectionButtonURL] = useState("");
  const [cardContent, setCardContent] = useState([]);
  const isMobile = useMediaQuery({ maxWidth: 1023 });
  const [hovered, setHovered] = useState(false);
  const [bgColor, setBGColor] = useState("bg-white");
  const handleMouseOver = () => setHovered(true);
  const handleMouseOut = () => setHovered(false);

  const style = getContentStyle(content, isMobile);

  useEffect(() => {
    if (isDataLoaded) {
      if (
        content.background !== undefined &&
        content.background.color !== "white"
      ) {
        setBGColor("bg-gray-primary");
      }
      handleSectionButtonURL(content);
      distributeContent(content).then((fetchedCardContent) => {
        setCardContent(fetchedCardContent);
      });
    }
  }, [isDataLoaded, content]);
  function handleSectionButtonURL(content) {
    if (content.sectionButton && content.sectionButton.url) {
      sanityClient
        .getDocument(content.sectionButton.url._ref)
        .then((object) => {
          setSectionButtonURL(`/${object.slug}`);
        });
    }
  }

  return (
    isDataLoaded && (
      <div className={`cards-section ${bgColor}`}>
        <h3 className="cards-section-title">{content.title}</h3>
        <div className={style}>
          {cardContent.map((card, index) => (
            <CardContent
              card={card}
              index={index}
              cardStyle={style}
              cardType={card.cardType}
              key={index}
            />
          ))}
        </div>
        {renderSectionButton(
          content,
          sectionButtonURL,
          handleMouseOver,
          handleMouseOut,
          hovered
        )}
      </div>
    )
  );
}

function getContentStyle(content, isMobile) {
  if (content._type === "featuredCards") {
    return isMobile
      ? "featured-card-section-container-mobile"
      : "featured-card-section-container";
  } else {
    return isMobile
      ? "card-section-container-mobile"
      : "card-section-container";
  }
}

function renderSectionButton(
  content,
  sectionButtonURL,
  handleMouseOver,
  handleMouseOut,
  hovered
) {
  if (content.sectionButton && content.sectionButton.title) {
    return (
      <Link to={sectionButtonURL}>
        <button
          className="cards-section-button"
          style={{
            backgroundColor: hovered
              ? "var(--secondary-color)"
              : "var(--primary-color)",
          }}
          onMouseOver={handleMouseOver}
          onMouseOut={handleMouseOut}
        >
          {content.sectionButton.title}
        </button>
      </Link>
    );
  }
  return null;
}

async function distributeContent(content) {
  const productLink = async (obj) => {
    if ("url" in obj) {
      return obj.url;
    } else if ("page" in obj) {
      const ref = await getUrl(obj.page._ref);
      return `/${ref.slug}`;
    } else return "";
  };

  if (content.cards) {
    const cardPromises = content.cards.map(async (card, index) => {
      if (
        card !== undefined &&
        (card.page !== undefined || card._type === "productCard")
      ) {
        const getRefData = card.page ? await getUrl(card.page._ref) : {};

        if (content.cards[index]._type === "serviceCard") {
          return {
            title: card.title,
            image: card.image.img.asset,
            url: getRefData.slug,
            cardType: "Card",
          };
        } else if (content.cards[index]._type === "newsCard") {
          function formatDate(inputDate) {
            const dateParts = inputDate.split("-");
            const formattedDate = `${dateParts[2]}.${dateParts[1]}.${dateParts[0]}`;
            return formattedDate;
          }
          let date = publishDate(getRefData._createdAt);
          if ("customPublishDate" in card) {
            date = formatDate(card.customPublishDate);
          }
          return {
            title: card.title,
            publishDate: date,
            image: card.image.img.asset,
            url: getRefData.slug,
            cardType: "Card",
          };
        } else if (content.cards[index]._type === "productCard") {
          const url = await productLink(card.link);
          return {
            title: card.title,
            description: card.description,
            price: card.price,
            image: card.image.img.asset,
            url: url,
            cardType: "CardXL",
          };
        }
      }
      return null;
    });

    const fetchedCardContent = await Promise.all(cardPromises);
    return fetchedCardContent.filter((card) => card !== null);
  }
  return [];
}

async function getUrl(ref) {
  const data = await sanityClient.getDocument(ref);
  return data;
}

function publishDate(inputDate) {
  const date = new Date(inputDate);
  const day = date.getDate().toString().padStart(2, "0");
  const month = (date.getMonth() + 1).toString().padStart(2, "0");
  const year = date.getFullYear();
  return `${day}.${month}.${year}`;
}

export default CardsSection;
