import React, { useState, useEffect, useContext } from "react";
import sanityClient from "../client";
import { SettingsContext } from "../context";
import { domainName } from "../functions";

const NewsletterForm = ({ mainColor, secondaryColor }) => {
  const settings = useContext(SettingsContext);
  const [email, setEmail] = useState("");
  const [hovered, setHovered] = useState(false);

  useEffect(() => {}, [settings]);

  const handleMouseOver = () => {
    setHovered(true);
  };
  const handleMouseOut = () => {
    setHovered(false);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    // regular expression for email validation
    const emailRegEx = /^[\w-]+(\.[\w-]+)*@([\w-]+\.)+[a-zA-Z]{2,7}$/;

    if (!emailRegEx.test(email)) {
      alert("Моля въведете валиден имейл адрес!");
      return;
    }

    try {
      const response = await fetch("/.netlify/functions/subscribe", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          email: email,
        }),
      });

      const data = await response.json();

      if (response.ok) {
        setEmail("");
        alert("Успешно се записахте за нашият бюлетин!");
      } else {
        if (data.message === "Email already exists") {
          alert("Този имейл вече е записан в нашия бюлетин.");
        } else {
          console.error("Error saving the email:", response.statusText);
          alert("Възникна проблем. Моля опитайте отново по-късно.");
        }
      }
    } catch (error) {
      console.error("Error saving the email:", error);
      alert("Възникна проблем. Моля опитайте отново по-късно.");
    }
  };

  return (
    <form className="newsletter-form" onSubmit={handleSubmit}>
      <div className="newsletter-form-inner">
        <label htmlFor="email">
          Получавай нашите страхотни предложения на електлонната си поща:
        </label>
        <input
          type="email"
          id="email"
          name="email"
          value={email}
          onChange={(e) => {
            setEmail(e.target.value);
          }}
          required
          className="newsletter-form-input"
        />
        <button
          className="newsletter-form-submit-button"
          type="submit"
          style={{ background: hovered ? mainColor : secondaryColor }}
          onMouseOver={handleMouseOver}
          onMouseOut={handleMouseOut}
        >
          ОК
        </button>
      </div>
      <div className="newsletter-form-terms">
        <input
          className="newsletter-form-terms-checkbox-required"
          type="checkbox"
          required
        ></input>
        <label>
          Да, съгласен съм да получавам рекламна информация, касаеща нови
          продукти, текущи промоции, информационни текстове и всички други
          материали, свързани с предлаганите от нас услуги.
        </label>
      </div>
    </form>
  );
};

export default NewsletterForm;
