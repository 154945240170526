import { React, useContext, useState, useEffect } from "react";
import { HomePageContentContext, SettingsContext } from "../context";
import placeholder from "../media/placeholder-image.png";
import { urlFor } from "../functions";
import { defaultPrimaryColor, defaultSecondaryColor } from "../functions";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import "../style/style.css";

function ImageCarousel({ content, background }) {
  const settings = useContext(SettingsContext);
  let isDataLoaded = settings !== undefined && content !== undefined;
  const color =
    isDataLoaded && settings.colors && settings.colors.secondary
      ? settings.colors.secondary.hex
      : defaultSecondaryColor;

  const [images, setImages] = useState([]);
  const [bgColor, setBgColor] = useState("bg-white");

  let imageArray = new Array();
  useEffect(() => {
    if (isDataLoaded) {
      if (background !== undefined && background !== "white") {
        setBgColor("bg-gray-primary");
      }
      for (let i = 0; i < content.length; i++) {
        let imageSource = urlFor(content[i].img.asset._ref)
          .auto("format")
          .fit("max")
          .toString();
        imageArray.push(imageSource);
      }
    }
    setImages(imageArray);
  }, [isDataLoaded]);

  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 1,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 1,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 1,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };

  return (
    isDataLoaded && (
      <Carousel
        swipeable={true}
        draggable={false}
        showDots={true}
        responsive={responsive}
        ssr={true}
        infinite={true}
        keyBoardControl={true}
        autoPlay={true}
        arrows={false}
        dotListClass="carousel-dot"
      >
        {images.map((src, index) => {
          return (
            <div key={index} className={`carousel ${bgColor}`}>
              <img className="carousel-image" src={src} />
            </div>
          );
        })}
      </Carousel>
    )
  );
}

export default ImageCarousel;
