import { React, useState } from "react";
import ImageCarousel from "../components/ImageCarousel";
import TextWithImageSection from "../components/TextWithImageSection";
import CardsSection from "../components/CardsSection";
import TitleSection from "../components/TitleSection";
import Meta from "../components/Meta";
import TextWithGoogleMapSection from "../components/TextWithGoogleMapSection";

function Home({ content, metaDescription }) {
  const isDataLoaded = content !== undefined;
  const itemsFromContent = Object.values(isDataLoaded ? content.content : [{}]);
  const itemTypes = itemsFromContent.map((item, index) => {
    return item._type;
  });

  function buildPage() {
    let component;
    let componentsArray = [];
    let sectionsArray = [];

    itemTypes.map((type, index) => {
      if (type === "titleSection") {
        component = (
          <TitleSection
            content={itemsFromContent[index]}
            key={`${index}-${type}`}
          />
        );
        componentsArray.push(component);
      } else if (type === "carousel") {
        component = (
          <ImageCarousel
            content={itemsFromContent[index].content}
            background={
              itemsFromContent[index].background !== undefined
                ? itemsFromContent[index].background.color
                : "white"
            }
            key={`${index}-${type}`}
          />
        );
        componentsArray.push(component);
      } else if (type === "cards") {
        component = (
          <CardsSection
            content={itemsFromContent[index]}
            key={`${index}-${type}`}
          />
        );
        componentsArray.push(component);
      } else if (type === "featuredCards") {
        component = (
          <CardsSection
            content={itemsFromContent[index]}
            cardType="featured"
            key={`${index}-${type}`}
          />
        );
        componentsArray.push(component);
      } else if (type === "textImageSection") {
        component = (
          <TextWithImageSection
            content={itemsFromContent[index]}
            key={`${index}-${type}`}
          />
        );
        componentsArray.push(component);
      } else if (type === "textGoogleMapSection") {
        component = (
          <TextWithGoogleMapSection
            content={itemsFromContent[index]}
            key={`${index}-${type}`}
          />
        );
        componentsArray.push(component);
      }
    });
    return componentsArray;
  }

  return (
    <>
      <Meta description={metaDescription !== null ? metaDescription : ""} />
      {content ? (
        <div className="home">{buildPage()}</div>
      ) : (
        <div className="h-screen"></div>
      )}
    </>
  );
}

export default Home;
