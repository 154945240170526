import React, { useState, useEffect } from "react";
import { PortableText } from "@portabletext/react";
import sanityClient from "../client";
import { urlFor } from "../functions";
import { useMediaQuery } from "react-responsive";
import CustomImage from "./CustomImage";

function TextWithImageSection({ content }) {
  let isDataLoaded = content !== undefined;
  const [flexDirection, setFlexDirection] = useState("row-reverse");
  const [textWidth, setTextWidth] = useState(100);
  const [imageWidth, setImageWidth] = useState(100);
  const [textAlign, setTextAlign] = useState("justify");
  const isMobile = useMediaQuery({ maxWidth: 1023 });
  let imageURL =
    isDataLoaded &&
      content.image &&
      content.image.img &&
      content.image.img.asset
      ? urlFor(content.image.img.asset._ref)
      : null;
  let imageAltText =
    isDataLoaded && content.image && content.altText ? content.altText : null;

  useEffect(() => {
    if (isDataLoaded) {
      if (isMobile) {
        setTextWidth(100);
        setImageWidth(100);

        if (content.imagePlacement === "left" || content.imagePlacement === "above") {
          setFlexDirection("column");
        } else if (content.imagePlacement === "right" || content.imagePlacement === "below") {
          setFlexDirection("column-reverse");
        }

      } else {
        if (content.textWidth === 100) {
          setTextWidth(100);
          setImageWidth(100);

          if (content.imagePlacement === "left" || content.imagePlacement === "above") {
            setFlexDirection("column");
          } else if (content.imagePlacement === "right" || content.imagePlacement === "below") {
            setFlexDirection("column-reverse");
          }

        } else {
          setTextWidth(content.textWidth);
          setImageWidth(100 - textWidth);
          if (content.imagePlacement === "left") {
            setFlexDirection("row");
          } else if (content.imagePlacement === "right") {
            setFlexDirection("row-reverse");
          } else if (content.imagePlacement === "above") {
            setTextWidth(100);
            setImageWidth(100);
            setFlexDirection("column");
          } else if (content.imagePlacement === "below") {
            setTextWidth(100);
            setImageWidth(100);
            setFlexDirection("column-reverse");
          }
        }
      }
      setTextAlign(content.textAlign);
    }
  }, [isDataLoaded, textWidth, imageWidth, flexDirection]);

  const Text = () => {
    return (
      <div
        className="break-words"
        style={{ width: `${textWidth}%`, textAlign: textAlign }}
      >
        <PortableText value={content.text} />
      </div>
    );
  };

  const Image = () => {
    return (
      <img
        src={imageURL}
        style={{ width: `${imageWidth}%`, height: "auto" }}
        alt={imageAltText}
      ></img>
    );
  };

  const TextWithImage = () => {
    return (
      <div className="image-paragraph">
        <div
          className="image-paragraph-text"
          style={{
            flexDirection: flexDirection,
          }}
        >
          {imageURL && (
            <CustomImage
              src={imageURL}
              alt={imageAltText}
              imageWidth={imageWidth}
              key="image"
            />
          )}
          <Text key="text" />
        </div>
      </div>
    );
  };

  return isDataLoaded && <TextWithImage />;
}

export default TextWithImageSection;
