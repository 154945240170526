import React, { useContext, useState, useEffect } from "react";
import {
  SettingsContext,
  PagesContentContext,
  NavigationContext,
} from "../context";
import { setIcon, setText } from "../functions";
import Bulletin from "./Bulletin";
import { Link } from "react-router-dom";
import { defaultPrimaryColor, defaultSecondaryColor } from "../functions";
import RightsReserved from "./RightsReserved";
import sanityClient from "../client.js";

function Logo({ settings }) {
  if (settings && settings.footerLogo) {
    return (
      <img
        className="footer-logo"
        src={settings.footerLogo.asset.url}
        alt="logo"
      ></img>
    );
  } else {
    return <div className="footer-logo-placeholder"></div>;
  }
}

function NavItems({ items }) {
  return items.map((item, index) => (
    <Link to={`/${item.url}`} key={`nav-item-${index}`}>
      <span key={`nav-item-${item.title}`} style={{ color: "white" }}>
        {item.title}
      </span>
    </Link>
  ));
}

function Footer() {
  const settings = useContext(SettingsContext);
  const pages = useContext(PagesContentContext);
  const navigation = useContext(NavigationContext);
  const [navItems, setNavItems] = useState([]);
  const [mainColor, setMainColor] = useState(defaultPrimaryColor);
  const [secondaryColor, setSecondaryColor] = useState(defaultSecondaryColor);

  const isDataLoaded =
    settings !== undefined &&
    pages !== undefined &&
    navigation !== undefined &&
    navItems !== undefined;
  const whiteColor = "#fff";

  useEffect(() => {
    const getPageFromRef = async (ref) => {
      const data = await sanityClient.getDocument(ref);
      return data;
    };

    if (
      isDataLoaded &&
      settings.colors &&
      settings.colors.main &&
      settings.colors.secondary
    ) {
      setMainColor(settings.colors.main.hex);
      setSecondaryColor(settings.colors.secondary.hex);
    }

    if (isDataLoaded) {
      const buildNavItems = async () => {
        const navItemsArray = await Promise.all(
          navigation.map(async (item) => {
            const page = await getPageFromRef(item.page._ref);
            return {
              title: page.title,
              url: page.slug,
            };
          })
        );
        setNavItems(navItemsArray);
      };
      buildNavItems();
    }
  }, [isDataLoaded, navigation]);

  const businessInfo = isDataLoaded ? settings.businessInfo : {};

  return (
    <>
      <footer className="footer" style={{ background: mainColor }}>
        <div className="footer-container">
          <div className="footer-contact-info">
            <a href="/">
              <Logo settings={settings} />
            </a>
            <div className="footer-contact-info-container">
              {Object.keys(businessInfo).map((element, index) => (
                <div
                  key={`footer-contact-info-section-el-${element}`}
                  className="footer-contact-info-row"
                >
                  {setIcon(null, element, whiteColor, null)}
                  {setText(element, businessInfo[element])}
                </div>
              ))}
            </div>
          </div>
          <div className="footer-navigation">
            <span className="footer-navigation-title">
              {isDataLoaded ? "Информация" : ""}
            </span>
            <div className="footer-navigation-container">
              <Link to={"/"}>
                <span style={{ color: whiteColor }}>
                  {isDataLoaded ? "Начало" : ""}
                </span>
              </Link>
              <NavItems items={navItems} />
              <Link to={"/privacy-policy"}>
                <span style={{ color: whiteColor }}>
                  {isDataLoaded ? "Политика за поверителност" : ""}
                </span>
              </Link>
              <Link to={"/terms-and-conditions"}>
                <span style={{ color: whiteColor }}>
                  {isDataLoaded ? "Условия за ползване" : ""}
                </span>
              </Link>
            </div>
          </div>
          {settings && settings.hasNewsletter && (
            <div className="footer-bulletin-holder">
              <Bulletin />
            </div>
          )}
        </div>
      </footer>
      <RightsReserved text={settings ? settings.rightsReserved : ""} />
    </>
  );
}

export default Footer;
