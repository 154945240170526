import React, { useContext, useState, useEffect } from "react";
import { SettingsContext } from "../context";

function PrivacyPolicy() {
  const settings = useContext(SettingsContext);
  const [NAME, setNAME] = useState("");
  const [EMAIL, setEMAIL] = useState("");

  useEffect(() => {
    if (settings) {
      setNAME(settings.name);
      setEMAIL(settings.businessInfo.email);
    }
  }, [settings]);

  return (
    <div className="page">
      <div className="page-container">
        <h1 className="page-title" style={{ color: "var(--primary-color)" }}>
          Политика за поверителност
        </h1>
        <h3 className="page-subtitle">Събиране на лична информация</h3>
        <p className="paragraph">
          Единствената лична информация, която събираме от потребителите, е
          техният имейл адрес, когато се регистрират за нашия безплатен бюлетин.
        </p>
        <h3 className="page-subtitle">Използване на лична информация</h3>
        <p className="paragraph">
          Имейл адресите се използват само за изпращане на нашия промоционален
          бюлетин. Ние не използваме тази информация за други цели и не я
          споделяме с трети страни, освен с нашия доставчик на услуги за имейл
          маркетинг.
        </p>
        <h3 className="page-subtitle">Защита на лична информация</h3>
        <p className="paragraph">
          За да защитим личната информация на потребителите, ние се съобразяваме
          с политиките и практиките за сигурност на нашия имейл маркетинг доставчик, който управлява
          съхранението и обработката на имейл адресите.
        </p>
        <h3 className="page-subtitle">Права на потребителите</h3>
        <p className="paragraph">
          Потребителите имат право да се отпишат от нашия бюлетин по всяко
          време, като кликнат върху бутона "Отписване" в долната част на
          бюлетина.
        </p>
        <h3 className="page-subtitle">Бисквитки</h3>
        <p className="paragraph">Този уебсайт не използва бисквитки.</p>
        <h3 className="page-subtitle">Промени в Политиката за поверителност</h3>
        <p className="paragraph">
          <span className="font-semibold"> {NAME} </span> може да променя тази
          Политика за поверителност по всяко време без предупреждение. Промените
          ще влязат в сила веднага след публикуването на новата Политика за
          поверителност на този уебсайт.
        </p>
        <h3 className="page-subtitle">Контакти</h3>
        <p className="paragraph">
          Ако имате въпроси относно тази Политика за поверителност, моля
          свържете се с нас на{" "}
          <a href={`mailto:${EMAIL}`} className="font-semibold">
            {" "}
            {EMAIL}
          </a>
          .
        </p>
        <p className="paragraph italic mt-10">
          Дизайн и разработка на уебсайта -{" "}
          <a href={"mailto:office@foks.bg"} className="font-semibold">
            ФОКС - PR и Рекламна агенция
          </a>
          .
        </p>
      </div>
    </div>
  );
}

export default PrivacyPolicy;
