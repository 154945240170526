import { React, useContext } from "react";
import { SettingsContext } from "../context";
import { defaultPrimaryColor, setIcon, setText } from "../functions";
import { useMediaQuery } from "react-responsive";

function TopPanel() {
  const settings = useContext(SettingsContext);
  const isMobile = useMediaQuery({ maxWidth: 1023 });

  let color =
    settings && settings.colors && settings.colors.main
      ? settings.colors.main.hex
      : defaultPrimaryColor;

  return (
    <div className="top-panel-holder">
      <div className="top-panel">
        {settings &&
          Object.keys(settings.businessInfo).map((element, index) => {
            return (
              <div
                key={`top-panel-element-${element}`}
                className="top-panel-element"
              >
                {setIcon(null, element, color)}
                {setText(element, settings.businessInfo[element])}
              </div>
            );
          })}
      </div>
    </div>
  );
}

export default TopPanel;
