import { React, useContext, useEffect, useState } from "react";
import GoogleMapReact from "google-map-react";
import { RiMapPin2Fill } from "react-icons/ri";
import { SettingsContext } from "../context";
import { defaultPrimaryColor, googleMapsAPI } from "../functions";

function LocationMap({ locationName, lat, lng, width }) {
  const settings = useContext(SettingsContext);
  let color =
    settings && settings.colors && settings.colors.main
      ? settings.colors.main.hex
      : defaultPrimaryColor;

  const location = {
    address: locationName,
    lat: parseFloat(lat),
    lng: parseFloat(lng),
  };

  const Map = ({ location, zoomLevel }) => (
    <div style={{ height: "50vh", width: `${width}%` }}>
      <GoogleMapReact
        bootstrapURLKeys={{ key: googleMapsAPI }}
        defaultCenter={location}
        defaultZoom={zoomLevel}
      >
        <LocationPin
          lat={location.lat}
          lng={location.lng}
          text={location.address}
        />
      </GoogleMapReact>
    </div>
  );

  const LocationPin = ({ text }) => (
    <div className="pin">
      <RiMapPin2Fill size={35} fill={color} />
      <p className="pin-text">{text}</p>
    </div>
  );

  return <Map location={location} zoomLevel={17} />;
}

export default LocationMap;
