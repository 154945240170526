import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { urlFor } from "../functions";
import placeholder from "../media/placeholder-image.png";
import { PortableText } from "@portabletext/react";
import { useMediaQuery } from "react-responsive";

function CardXL({ image, title, description, link, price }) {
  const [source, setSource] = useState("");
  const [hovered, setHovered] = useState(false);
  const handleMouseOver = () => setHovered(true);
  const handleMouseOut = () => setHovered(false);
  const isMobile = useMediaQuery({ maxWidth: 1023 });

  useEffect(() => {
    setSource(urlFor(image).auto("format").fit("max").toString());
  }, []);

  const MobileCardXL = () => {
    return (
      <a className="card-xl" href={link}>
        <div
          className="card-xl-image"
          style={{
            backgroundImage: `url(${source})`,
          }}
        ></div>
        <div className="card-xl-container">
          <div className="card-xl-container-inner">
            <h4 className="card-xl-title">
              {title}
            </h4>
            <PortableText value={description} />
          </div>
          <div className="card-xl-price-button-section">
            <span
              className="font-bold text-[18px] leading-[22px] lg:text-[28px] lg:leading-[35px]"
              style={{
                color: "var(--primary-color)",
              }}
            >
              {price}
            </span>
          </div>
        </div>
      </a>
    );
  };

  const DestopCardXL = () => {
    return (
      <div className="card-xl" href={link}>
        <div
          className="card-xl-image"
          style={{
            backgroundImage: `url(${source})`,
          }}
        ></div>
        <div className="card-xl-container">
          <div className="card-xl-title-description">
            <h4 className="card-xl-title">
              {title}
            </h4>
            <PortableText value={description} />
          </div>
          <div className="card-xl-price-button-section">
            <span
              className="card-xl-price"
              style={{
                color: "var(--primary-color)",
              }}
            >
              {price}
            </span>
            <a
              href={link}
              className="card-xl-button"
              style={{
                backgroundColor: hovered
                  ? "var(--secondary-color)"
                  : "var(--primary-color)",
                display: "inline-block",
                textDecoration: "none",
              }}
              onMouseOver={handleMouseOver}
              onMouseOut={handleMouseOut}
            >
              Повече
            </a>
          </div>
        </div>
      </div>
    );
  };

  return isMobile ? <MobileCardXL /> : <DestopCardXL />;
}

export default CardXL;
