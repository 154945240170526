import { React, useContext, useState, useEffect } from "react";
import { SettingsContext } from "../context";
import { defaultPrimaryColor, defaultSecondaryColor } from "../functions";
import NewsletterForm from "./NewsletterForm";

function Bulletin() {
  const settings = useContext(SettingsContext);
  const isDataLoaded = settings !== undefined;
  const [primaryColor, setPrimaryColor] = useState(defaultPrimaryColor);
  const [secondaryColor, setSecondaryColor] = useState(defaultSecondaryColor);

  useEffect(() => {
    if (isDataLoaded) {
      if (
        settings.colors &&
        settings.colors.main &&
        settings.colors.secondary
      ) {
        setPrimaryColor(settings.colors.main.hex);
        setSecondaryColor(settings.colors.secondary.hex);
      }
    }
  }, [isDataLoaded]);

  return (
    <section className="bulletin">
      <span className="bulletin-title">Бюлетин</span>
      <NewsletterForm
        mainColor={primaryColor}
        secondaryColor={secondaryColor}
      />
    </section>
  );
}

export default Bulletin;
