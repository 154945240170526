import React, { useState, useEffect } from "react";
import { PortableText } from "@portabletext/react";
import sanityClient from "../client";
import { urlFor } from "../functions";
import { useMediaQuery } from "react-responsive";
import LocationMap from "./LocationMap";

function TextWithGoogleMapSection({ content }) {
  let isDataLoaded = content !== undefined;
  const [flexDirection, setFlexDirection] = useState("row-reverse");
  const [textWidth, setTextWidth] = useState(100);
  const [mapWidth, setMapWidth] = useState(100);
  const [textAlign, setTextAlign] = useState("justify");
  const isMobile = useMediaQuery({ maxWidth: 1023 });

  useEffect(() => {
    if (isDataLoaded) {
      if (isMobile) {
        setTextWidth(100);
        setMapWidth(100);

        if (
          content.mapPlacement === "left" ||
          content.mapPlacement === "above"
        ) {
          setFlexDirection("column");
        } else if (
          content.mapPlacement === "right" ||
          content.mapPlacement === "below"
        ) {
          setFlexDirection("column-reverse");
        }
      } else {
        if (content.textWidth === 100) {
          setTextWidth(100);
          setMapWidth(100);

          if (
            content.mapPlacement === "left" ||
            content.mapPlacement === "above"
          ) {
            setFlexDirection("column");
          } else if (
            content.mapPlacement === "right" ||
            content.mapPlacement === "below"
          ) {
            setFlexDirection("column-reverse");
          }
        } else {
          setTextWidth(content.textWidth);
          setMapWidth(100 - textWidth);
          if (content.mapPlacement === "left") {
            setFlexDirection("row");
          } else if (content.mapPlacement === "right") {
            setFlexDirection("row-reverse");
          } else if (content.mapPlacement === "above") {
            setTextWidth(100);
            setMapWidth(100);
            setFlexDirection("column");
          } else if (content.mapPlacement === "below") {
            setTextWidth(100);
            setMapWidth(100);
            setFlexDirection("column-reverse");
          }
        }
      }
      setTextAlign(content.textAlign);
    }
  }, [isDataLoaded, textWidth, mapWidth, flexDirection]);
  
  console.log({ textWidth, mapWidth, flexDirection });
  console.log(content);

  const Text = () => {
    return (
      <div
        className="break-words"
        style={{ width: `${textWidth}%`, textAlign: textAlign }}
      >
        <PortableText value={content.text} />
      </div>
    );
  };

  const TextWithMap = () => {
    return (
      <div className="map-paragraph">
        <div
          className="map-paragraph-text"
          style={{
            flexDirection: flexDirection,
          }}
        >
          {content.map.name && content.map.lat && content.map.lng && (
            <LocationMap
              locationName={content.map.name}
              lat={content.map.lat}
              lng={content.map.lng}
              width={mapWidth}
              key="google-map"
            />
          )}
          <Text key="text" />
        </div>
      </div>
    );
  };

  return isDataLoaded && <TextWithMap />;
}

export default TextWithGoogleMapSection;
