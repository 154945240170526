import React, { useState, useEffect, useContext, createContext } from "react";
import sanityClient from "./client";
import api from "./apiCalls";
import {
  setSiteName,
  setFavicon,
  fetchData,
  setPageTitleAndSlug,
  setColors,
  defaultPrimaryColor,
  defaultSecondaryColor,
} from "./functions";
import {
  SettingsContext,
  HomePageContentContext,
  PagesContentContext,
  NavigationContext,
  SendInBlueContext,
} from "./context";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Footer from "./components/Footer";
import Home from "./pages/Home";
import Page from "./pages/Page";
import NotFound from "./pages/NotFound";
import Header from "./components/Header";
import ScrollToTop from "./utils/ScrollToTop";
import { Helmet, HelmetProvider } from "react-helmet-async";
import TermsAndConditions from "./pages/TermsAndConditions";
import PrivacyPolicy from "./pages/PrivacyPolicy";

function App() {
  const [settings, setSettings] = useState();
  const [homePageContent, setHomePageContent] = useState();
  const [allPagesSlugs, setAllPagesSlugs] = useState([{}]);
  const [allPagesContent, setAllPagesContent] = useState([{}]);
  const [navigation, setNavigation] = useState([]);
  const [homePageMetaDescription, setHomePageMetaDescription] = useState("");
  const [pagesMetaDescriptions, setPagesMetaDescriptions] = useState([]);
  const [pageMetas, setPageMetas] = useState([]);

  let isDataLoaded =
    settings &&
    homePageContent &&
    allPagesSlugs &&
    allPagesContent &&
    navigation;

  useEffect(() => {
    fetchData(api.siteSettings).then((data) => {
      if (data !== null) {
        if (data.name) {
          setSiteName(data.name);
        }
        if (data.favicon) {
          setFavicon(data.favicon.asset.url);
        }
        setSettings(data);
        if (data.colors && data.colors.main && data.colors.secondary) {
          setColors(data.colors.main.hex, data.colors.secondary.hex);
        } else {
          setColors(defaultPrimaryColor, defaultSecondaryColor);
        }
      } else {
        setColors(defaultPrimaryColor, defaultSecondaryColor);
      }
    });
    fetchData(api.homePageContent).then((data) => {
      if (data) {
        setHomePageContent(data);
        if (data.metaDescription !== null) {
          setHomePageMetaDescription(data.metaDescription);
        }
      }
    });
    fetchData(api.pagesContent).then((data) => {
      setAllPagesSlugs(setPageTitleAndSlug(data));
      setAllPagesContent(data);
      let pageMetasArray = [];
      data.map((page) => {
        pageMetasArray.push(page.metaDescription);
      });
      setPageMetas(pageMetasArray);
    });
    fetchData(api.navigation).then(async (data) => {
      if (data !== null) {
        const navigationPromises = data.menu.map((item) => {
          return item;
        });
        const navigationPages = await Promise.all(navigationPromises);
        setNavigation(navigationPages);
      }
    });
  }, []);

  return (
    <BrowserRouter>
      <ScrollToTop />
      <SettingsContext.Provider value={settings}>
        <PagesContentContext.Provider value={allPagesContent}>
          <HomePageContentContext.Provider value={homePageContent}>
            <NavigationContext.Provider value={navigation}>
              <HelmetProvider>
                <Header />
                <Routes>
                  <Route
                    path="/"
                    element={
                      <Home
                        content={homePageContent}
                        metaDescription={homePageMetaDescription}
                      />
                    }
                  />
                  {pageMetas.length > 0 &&
                    allPagesSlugs.map((page, index) => {
                      return (
                        <Route
                          key={`page-link-to-${page}`}
                          path={page.slug}
                          element={
                            <Page
                              title={page.title}
                              content={allPagesContent[index].content}
                              metaDescription={pageMetas[index]}
                            />
                          }
                        />
                      );
                    })}
                  <Route
                    path="/terms-and-conditions"
                    element={<TermsAndConditions />}
                  />
                  <Route path="/privacy-policy" element={<PrivacyPolicy />} />
                  <Route path="*" element={<NotFound />} />
                </Routes>
                <Footer />
              </HelmetProvider>
            </NavigationContext.Provider>
          </HomePageContentContext.Provider>
        </PagesContentContext.Provider>
      </SettingsContext.Provider>
    </BrowserRouter>
  );
}

export default App;
