import imageUrlBuilder from "@sanity/image-url";
import sanityClient from "./client";
import { FaMapMarkerAlt } from "react-icons/fa";
import { MdEmail } from "react-icons/md";
import { ImPhone } from "react-icons/im";
import { AiFillClockCircle } from "react-icons/ai";
import { BsFacebook } from "react-icons/bs";
import { RiInstagramFill } from "react-icons/ri";
import { FaTiktok } from "react-icons/fa";
import { BsTwitter } from "react-icons/bs";
import { BsYoutube } from "react-icons/bs";
import "./style/style.css";

export function setSiteName(name) {
  document.title = name;
}

export function setFavicon(icon) {
  if (icon !== undefined) {
    document.getElementById("favicon").href = icon;
    document.getElementById("apple-favicon").href = icon;
  }
}

export const fetchData = async (request) => {
  const data = await sanityClient
    .fetch(request)
    .catch((error) => console.error(error));
  return data;
};

export const setIcon = (size, type, color, link) => {
  const types = {
    address: FaMapMarkerAlt,
    email: MdEmail,
    phone: ImPhone,
    workTime: AiFillClockCircle,
    facebook: BsFacebook,
    instagram: RiInstagramFill,
    tiktok: FaTiktok,
    twitter: BsTwitter,
    youtube: BsYoutube,
  };

  const TypeComponent = types[type];
  const socialMedias = [
    "facebook",
    "instagram",
    "tiktok",
    "twitter",
    "youtube",
  ];

  if (socialMedias.some((x) => x === type)) {
    return (
      <a key={type} href={link} target="_blank">
        <TypeComponent
          color={color}
          className="link-to-social-media"
          size={size}
        />
      </a>
    );
  } else {
    return <TypeComponent className="w-[22px] h-[22px]" fill={color} />;
  }
};

export const setText = (type, text) => {
  if (type === "email") {
    return <a href={`mailto:${text}`}>{text}</a>;
  } else if (type === "phone") {
    return <a href={`tel:${text}`}>{text}</a>;
  } else return <p>{text}</p>;
};

export const setPageTitleAndSlug = (data) => {
  let pages = [];
  data.map((p, index) => {
    let page = { slug: `/${p.slug}`, title: p.title };
    pages.push(page);
  });
  return pages;
};

export const setColors = (main, secondary) => {
  document.documentElement.style.setProperty("--primary-color", main);
  document.documentElement.style.setProperty("--secondary-color", secondary);
};

export const url = imageUrlBuilder(sanityClient);

export function urlFor(source) {
  return url.image(source);
}

export const defaultPrimaryColor = "#ff4949";
export const defaultSecondaryColor = "#0f4392";
export const googleMapsAPI = "";
