import { React, useContext, useState, useEffect } from "react";
import { SettingsContext } from "../context";
import { defaultPrimaryColor, defaultSecondaryColor } from "../functions";

function TitleSection({ content }) {
  const settings = useContext(SettingsContext);
  let isDataLoaded = settings !== undefined && content !== undefined;
  const color =
    isDataLoaded && settings.colors && settings.colors.main
      ? settings.colors.main.hex
      : defaultPrimaryColor;

  return (
    <div className="title-section" style={{ backgroundColor: color }}>
      <h2 className="title-section-title">
        {isDataLoaded ? content.title : ""}
      </h2>
      <p className="title-section-subtitle">
        {isDataLoaded ? content.mission : ""}
      </p>
    </div>
  );
}

export default TitleSection;
