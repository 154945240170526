import React, { useState } from "react";

function Image({ src, alt, className, imageWidth }) {
  const [isEnlarged, setIsEnlarged] = useState(false);

  const handleClick = () => {
    if (window.innerWidth >= 1024) {
      setIsEnlarged(!isEnlarged);
      document.body.style.overflow = isEnlarged ? "auto" : "hidden";
    }
  };

  return (
    <>
      <img
        src={src}
        alt={alt}
        className={`${className} cursor-pointer`}
        style={{ width: `${imageWidth}%`, height: "auto" }}
        onClick={handleClick}
      />
      {isEnlarged && (
        <div className="image-fullscreen-overlay" onClick={handleClick}>
          <div className="image-fullscreen-holder">
            <img src={src} alt={alt} className="image" />
            <button className="exit-fullscreen-button" onClick={handleClick}>
              &times;
            </button>
          </div>
        </div>
      )}
    </>
  );
}

export default Image;
