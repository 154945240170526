export default {
  siteSettings: `*[_type == 'siteSettings'][0]{
  name,
  businessInfo,
  rightsReserved,
  socialMedia,
  favicon{asset->{url}},
  headerLogo{asset->{url}},
  footerLogo{asset->{url}},
  colors,
  hasNewsletter
  }`,
  navigation: `*[_type == 'navigation'][0]{
  menu
  } `,
  homePageContent: `*[_type == 'home'][0]  {
  content,
  metaDescription
  } `,
  pagesContent: `*[_type == 'page']  {
  slug,
  title,
  content,
  metaDescription
  } `,
  sendInBlue: `*[_type == 'bulletin']  {
    } `,
};
