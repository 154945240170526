import React, {
  useContext,
  useState,
  useEffect,
  useCallback,
  useRef,
} from "react";
import {
  SettingsContext,
  NavigationContext,
  PagesContentContext,
} from "../context";
import { Link, NavLink } from "react-router-dom";
import { defaultPrimaryColor, setIcon } from "../functions";
import "../style/style.css";
import { useMediaQuery } from "react-responsive";
import { motion, AnimatePresence } from "framer-motion";
import NavItem from "./NavItem";
import sanityClient from "../client.js";

function Navigation({ setIsMenuOpen }) {
  const settings = useContext(SettingsContext);
  const navigation = useContext(NavigationContext);
  const pages = useContext(PagesContentContext);
  const [navItems, setNavItems] = useState([]);
  const isDataLoaded = settings && navigation.length > 0 && pages;
  const isMobile = useMediaQuery({ maxWidth: 1023 });
  const [menuOpen, setMenuOpen] = useState(false);
  const [expandedSubmenus, setExpandedSubmenus] = useState([]);
  const [openDropdown, setOpenDropdown] = useState(-1);
  const [dropdownActive, setDropdownActive] = useState(-1);

  const handleDropdown = (index) => {
    setDropdownActive(index);
  };

  const handleSubMenuExpansion = (index) => {
    const newExpandedSubmenus = [...expandedSubmenus];
    newExpandedSubmenus[index] = !newExpandedSubmenus[index];
    setExpandedSubmenus(newExpandedSubmenus);
  };
  const handleMenuToggle = useCallback(() => {
    setMenuOpen((prevMenuOpen) => {
      setIsMenuOpen(!prevMenuOpen);
      return !prevMenuOpen;
    });
  }, [setIsMenuOpen]);

  useEffect(() => {
    const getPageFromRef = async (ref) => {
      const data = await sanityClient.getDocument(ref);
      return data;
    };

    if (isDataLoaded) {
      const socialMedia = settings.socialMedia;
      const socialMediaPlatforms = socialMedia ? Object.keys(socialMedia) : [];
      const socialMediaLinks = socialMedia ? Object.values(socialMedia) : [];

      const buildNavItems = async () => {
        const navItemsArray = [];

        for (const navItem of navigation) {
          const page = await getPageFromRef(navItem.page._ref);
          const getSubMenuItems = async () => {
            if ("subMenuItems" in navItem) {
              const subMenuItems = await Promise.all(
                navItem.subMenuItems.map((subMenuItem) =>
                  getPageFromRef(subMenuItem._ref)
                )
              );
              return subMenuItems;
            }
            return null;
          };

          const subMenuItems = await getSubMenuItems();
          navItemsArray.push({
            title: page.title,
            url: page.slug,
            color: defaultPrimaryColor,
            type: "link-to-page",
            subMenuItems: subMenuItems
              ? subMenuItems.map((subMenuItem) => ({
                  title: subMenuItem.title,
                  url: subMenuItem.slug,
                }))
              : null,
          });
        }

        setNavItems([
          ...navItemsArray,
          ...socialMediaPlatforms.map((platform, i) => ({
            title: platform,
            url: socialMediaLinks[i],
            color: defaultPrimaryColor,
            type: "link-to-social-media",
          })),
        ]);
      };

      buildNavItems();
    }
  }, [isDataLoaded, settings]);

  const MenuItems = ({ isDesktop, handleMenuToggle }) => {
    const dropDownRef = useRef(null);
    const handleOutsideClick = (event) => {
      if (dropDownRef.current && !dropDownRef.current.contains(event.target)) {
        setOpenDropdown(-1);
      }
    };

    return (
      <>
        {navItems.map((navItem, i) => {
          if (navItem.type === "link-to-page") {
            return (
              <React.Fragment key={`link-to-${navItem.title}`}>
                <NavItem
                  url={navItem.url}
                  title={navItem.title}
                  subMenuItems={navItem.subMenuItems}
                  onClick={
                    !isDesktop && navItem.subMenuItems
                      ? () => {
                          setOpenDropdown(i === openDropdown ? -1 : i);
                        }
                      : handleMenuToggle
                  }
                  mobileMode={!isDesktop}
                  dropdownActive={dropdownActive === i}
                  handleDropdown={() => handleDropdown(i)}
                  handleDropdownLeave={() => handleDropdown(-1)}
                />
                {!isDesktop && i === openDropdown && navItem.subMenuItems && (
                  <div className="mobile-submenu" ref={dropDownRef}>
                    <div
                      className="py-1"
                      role="menu"
                      aria-orientation="vertical"
                      aria-labelledby="options-menu"
                    >
                      {navItem.subMenuItems.map((subMenuItem, index) => (
                        <NavLink
                          to={`/${subMenuItem.url}`}
                          className="mobile-submenu-item"
                          role="menuitem"
                          key={index}
                          onClick={() => {
                            setOpenDropdown(i === openDropdown ? -1 : i);
                            handleMenuToggle();
                          }}
                        >
                          {subMenuItem.title}
                        </NavLink>
                      ))}
                      <NavLink
                        to={`/${navItem.url}`}
                        className="mobile-submenu-item"
                        role="menuitem"
                        onClick={() => {
                          setOpenDropdown(i === openDropdown ? -1 : i);
                          handleMenuToggle();
                        }}
                      >
                        Виж всички
                      </NavLink>
                    </div>
                  </div>
                )}
              </React.Fragment>
            );
          } else if (navItem.type === "link-to-social-media") {
            return (
              <span key={`link-to-${navItem.title}`}>
                {setIcon(
                  isDesktop ? 24 : 32,
                  navItem.title,
                  "var(--primary-color)",
                  navItem.url
                )}
              </span>
            );
          }
          return null;
        })}
      </>
    );
  };

  const MobileMenuIcon = () => {
    const style = {
      parentOpen: "mobile-menu-icon-parent-open",
      parentClosed: "mobile-menu-icon-parent-closed",
      upperLineOpen: "line-upper-open",
      lowerLineOpen: "line-lower-open",
      upperLineClosed: "line-upper-closed",
      lowerLineClosed: "line-lower-closed",
    };

    return (
      <label className="mobile-menu-holder">
        <button
          className={menuOpen ? style.parentOpen : style.parentClosed}
          onClick={handleMenuToggle}
          id="menu-icon"
          name="menu-icon"
        >
          <div
            className={menuOpen ? style.upperLineOpen : style.upperLineClosed}
            style={{ backgroundColor: "var(--primary-color)" }}
          ></div>
          <div
            className={menuOpen ? style.lowerLineOpen : style.lowerLineClosed}
            style={{ backgroundColor: "var(--primary-color)" }}
          ></div>
        </button>
      </label>
    );
  };

  const Menu = () => {
    if (!isMobile) {
      return (
        <div className="logo-nav-container-inner">
          <MenuItems isDesktop={true} />
        </div>
      );
    } else {
      return (
        <div className="logo-nav-container-inner">
          <MobileMenuIcon />
          {menuOpen && (
            <div className="mobile-menu">
              <div className="mobile-menu-holder">
                <MenuItems
                  isDesktop={false}
                  handleMenuToggle={handleMenuToggle}
                />
              </div>
            </div>
          )}
        </div>
      );
    }
  };

  return isDataLoaded && <Menu />;
}

export default Navigation;
