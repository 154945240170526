import React, {
  useContext,
  useState,
  useEffect,
  useRef,
  useCallback,
} from "react";
import { SettingsContext } from "../context";
import { Link } from "react-router-dom";
import Navigation from "./Navigation";
import TopPanel from "./TopPanel";

function HeaderLogo({ settings }) {
  if (settings && settings.headerLogo) {
    return (
      <Link to={"/"}>
        <img
          className="header-logo"
          src={settings.headerLogo.asset.url}
          alt="logo"
        ></img>
      </Link>
    );
  } else {
    return <div className="header-logo-placeholder"></div>;
  }
}

function Header() {
  const settings = useContext(SettingsContext);
  let isDataLoaded = settings !== undefined;

  const [prevScrollPos, setPrevScrollPos] = useState(0);
  const [visible, setVisible] = useState(true);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const prevScrollY = useRef(0);

  const handleScroll = useCallback(() => {
    const currentScrollPos = window.scrollY;
    if (!isMenuOpen) {
      if (currentScrollPos > prevScrollY.current) {
        if (currentScrollPos >= 150) {
          setVisible(false);
        }
      } else {
        setVisible(true);
      }
    }
    prevScrollY.current = currentScrollPos;
  }, [isMenuOpen]);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [handleScroll]);

  return (
    <header className="header-top-panel" style={{ top: visible ? 0 : -154 }}>
      <div className="header-top-panel-inner">
        <TopPanel />
        <div className="logo-nav-container-holder">
          <div className="logo-nav-container">
            <HeaderLogo settings={settings} />
            <Navigation setIsMenuOpen={setIsMenuOpen} />
          </div>
        </div>
      </div>
    </header>
  );
}

export default Header;
